import { VoteStatus } from '../../dto/bid.dto';
import { PriceSortOptions } from '../../enums/sort-options.enum';
import { TenderStates } from '../../enums/tender-status.enum';

export const BIDS_ACTION_PREFIX = '[Bids State]';
export class FetchBidsByTenderIdAction {
  static readonly type = '${BIDS_ACTION_PREFIX} Fetch bids by tender id';
  constructor(public tenderId: string) {}
}

export class FetchBidsPaginated {
  static readonly type = `${BIDS_ACTION_PREFIX} Common action to Fetch paginated bids by tender id`;
  constructor(
    public tenderId: string,
    public favorite: boolean,
    public accepted: boolean,
    public offset: number,
    public limit: number,
    public sortOption?: PriceSortOptions,
    public searchText?: string
  ) {}
}

export class FetchNonFavoriteBidsPaginated {
  static readonly type = `${BIDS_ACTION_PREFIX} Fetch all paginated bids by tender id`;
  constructor(
    public tenderId: string,
    public offset: number,
    public limit: number,
    public sortOption?: PriceSortOptions,
    public searchText?: string
  ) {}
}

export class FetchTotalBidsByTenderId {
  static readonly type = `${BIDS_ACTION_PREFIX} Fetch total bids by tender id`;
  constructor(public tenderId: string) {}
}

export class FetchFavoriteBidsPaginated {
  static readonly type = `${BIDS_ACTION_PREFIX} Fetch paginated favorite bids by tender id`;
  constructor(
    public tenderId: string,
    public offset: number,
    public limit: number,
    public sortOption?: PriceSortOptions,
    public searchText?: string
  ) {}
}

export class FetchAwardedBid {
  static readonly type = `${BIDS_ACTION_PREFIX} Fetch awarded bid`;
  constructor(public tenderId: string) {}
}

export class AwardBidAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Award bid`;
  constructor(
    public bidId: string,
    public bidOptionId: string
  ) {}
}

export class MarkAsFavoriteBidAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Mark as favourite bid`;
  constructor(public bidId: string) {}
}

export class VoteBidAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Vote bid`;
  constructor(public bidId: string) {}
}

export class FetchBidByIdAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Fetch bid by id`;
  constructor(public bidId: string) {}
}

export class UpdateTenderStatusAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Update tender status`;
  constructor(public tenderStatus: TenderStates) {}
}

export class SetCurrentVoteAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Set current vote`;
  constructor(public currentVote: VoteStatus) {}
}

export class RejectAllBidsAction {
  static readonly type = `${BIDS_ACTION_PREFIX} Reject all bids`;
  constructor(
    public tenderId: string,
    public rejectionReason: string
  ) {}
}

export class ClearBids {
  static readonly type = `${BIDS_ACTION_PREFIX} Clear bids`;
}

export class SetBidFormIsValid {
  static readonly type = `${BIDS_ACTION_PREFIX} Set bid form is valid`;
  constructor(public formIsValid: boolean) {}
}

import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification/notification.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import { AdditionalCharge } from 'app/modules/tendering/models/additional-charges.model';
import { BidService, BidServiceData } from 'app/modules/tendering/models/bid-service-data.model';
import { BiddingDrawerConfig } from 'app/modules/tendering/models/bidding-drawer-config.model';
import { FCLRate, LCLRate } from 'app/modules/tendering/models/rate.model';
import { CreateBidService } from 'app/modules/tendering/services/create-bid.service';
import { SetBidFormIsValid } from 'app/modules/tendering/store/bids/bids.actions';
import { of, take, tap } from 'rxjs';
import {
  AddCurViewingServiceAdditionalCharges,
  CreateNewBid,
  FetchMyBids,
  RemoveCurViewingServiceAdditionalCharge,
  ResetBidState,
  SetAllBids,
  SetBidConfig,
  SetFormIsValid,
  SwitchCurViewingBid,
  SwitchCurViewingService,
  UpdateCurViewingServiceAdditionalCharge,
  UpdateCurrentViewingServiceFCLRates,
  UpdateCurrentViewingServiceLCLRates,
  UpdateSelectedCurrencies,
  UpdateSelectedCurrency,
  UpdateSubTotal,
  WithdrawBid,
} from './create-bid.actions';

interface CreateBidStateModel {
  currencies: Currency[];
  allBids: BidServiceData[];
  selectedCurrency: Currency;
  curViewingBid: BidServiceData;
  curViewingService: BidService;
  myBidCards: any[]; // TODO: Move this this to bids state
  config: BiddingDrawerConfig;
  formIsValid: boolean;
}

@State<CreateBidStateModel>({
  name: 'createBidWizard',
  defaults: {
    allBids: [],
    currencies: [],
    selectedCurrency: null,
    curViewingBid: null,
    curViewingService: null,
    myBidCards: [],
    config: null,
    formIsValid: true,
  },
})
@Injectable()
export class CreateBidState {
  constructor(
    private createBidService: CreateBidService,
    private notificationService: NotificationService
  ) {}

  @Selector()
  static allBids(state: CreateBidStateModel): BidServiceData[] {
    return state.allBids;
  }

  @Selector()
  static currencies(state: CreateBidStateModel): Currency[] {
    return state.currencies;
  }

  @Selector()
  static selectedCurrency(state: CreateBidStateModel): Currency {
    return state.selectedCurrency;
  }

  @Selector()
  static curViewingBid(state: CreateBidStateModel): BidServiceData {
    return state.curViewingBid;
  }

  @Selector()
  static curViewingBidServicesList(state: CreateBidStateModel): BidService[] {
    return state.curViewingBid.services;
  }

  @Selector()
  static curViewingService(state: CreateBidStateModel): BidService {
    return state.curViewingService;
  }

  @Selector()
  static curViewingServiceAdditionalCharges(state: CreateBidStateModel): AdditionalCharge[] {
    return state.curViewingService.additionalCharges;
  }

  @Selector()
  static curViewingServiceSubTotal(state: CreateBidStateModel): number {
    return state.curViewingService.subTotal;
  }

  @Selector()
  static bidConfig(state: CreateBidStateModel): BiddingDrawerConfig {
    return state.config;
  }

  @Selector()
  static curViewingBidTotal(state: CreateBidStateModel): number {
    return state.curViewingBid.total;
  }

  @Selector()
  static curViewingServiceTotal(state: CreateBidStateModel): number {
    return (
      state.curViewingService.subTotal +
      state.curViewingService.additionalCharges.reduce((acc, charge) => acc + charge.amount, 0)
    );
  }

  @Selector()
  static curViewingServiceFCLRates(state: CreateBidStateModel): FCLRate[] {
    return state.curViewingService.fclRates;
  }

  @Selector()
  static curViewingServiceLCLRates(state: CreateBidStateModel): LCLRate {
    return state.curViewingService.lclRates;
  }

  @Selector()
  static myBidCards(state: CreateBidStateModel): any[] {
    return state.myBidCards;
  }

  @Selector()
  static formIsValid(state: CreateBidStateModel): boolean {
    return state.formIsValid;
  }

  @Selector()
  static bidFormIsValid(state: CreateBidStateModel): boolean {
    return state.formIsValid;
  }

  @Action(SetBidFormIsValid)
  setBidFormIsValid({ patchState }: StateContext<CreateBidStateModel>, { formIsValid }: SetBidFormIsValid) {
    patchState({
      formIsValid,
    });
  }

  @Action(CreateNewBid)
  addNewBid({ patchState, getState }: StateContext<CreateBidStateModel>, { bid }: CreateNewBid) {
    patchState({
      allBids: [...getState().allBids, bid],
    });
  }

  @Action(UpdateSelectedCurrencies)
  updateSelectedCurrencies(
    { patchState }: StateContext<CreateBidStateModel>,
    { currencies }: UpdateSelectedCurrencies
  ) {
    patchState({
      currencies,
    });
    patchState({
      // TODO: Implement logic to select the first currency for now set it to the first one in the list
      selectedCurrency: currencies[0],
    });
  }

  @Action(UpdateSelectedCurrency)
  updateSelectedCurrency(
    { getState, patchState }: StateContext<CreateBidStateModel>,
    { currency }: UpdateSelectedCurrency
  ) {
    const state = getState();
    const updatedBids = state.allBids.map(bid => ({
      ...bid,
      currency: currency,
      services: bid.services.map(service => ({
        ...service,
        currency: currency,
      })),
    }));

    patchState({
      selectedCurrency: currency,
      allBids: updatedBids,
      curViewingBid: updatedBids.find(bid => bid.id === state.curViewingBid.id) || null,
      curViewingService: state.curViewingService
        ? {
            ...state.curViewingService,
            currency: currency,
          }
        : null,
    });
  }

  /**
   * Switches the currently viewed bid and updates the state accordingly.
   * This action performs the following steps:
   * 1. Updates the bids array with the latest information of the bid we're switching to.
   * 2. Sets the new current viewing bid and its first service as the current viewing service.
   * 3. Updates the state with the new current viewing bid, service, and the updated bids array.
   * 4. DO NOT MODIFY
   *
   * @param {StateContext<CreateBidStateModel>} param0 - The state context
   * @param {SwitchCurViewingBid} param1 - The action payload containing the new bid
   */
  @Action(SwitchCurViewingBid)
  switchCurViewingBid(
    { getState, patchState }: StateContext<CreateBidStateModel>,
    { bid: switchingBid }: SwitchCurViewingBid
  ) {
    const state = getState();
    const updatedBids = state.allBids.map(existingBid =>
      existingBid.id === switchingBid.id ? switchingBid : existingBid
    );

    patchState({
      allBids: updatedBids,
      curViewingBid: switchingBid,
      curViewingService: switchingBid.services[0],
    });
  }

  /**
   * Switches the currently viewed service and updates the state accordingly.
   * This action performs the following steps:
   * 1. Updates the bids array with the current service information.
   * 2. Finds the updated version of the service we're switching to.
   * 3. Updates the state with the new current viewing service and the updated bids array.
   * 4. DO NOT MODIFY
   *
   * @param {StateContext<CreateBidStateModel>} param0 - The state context
   * @param {SwitchCurViewingService} param1 - The action payload containing the new service
   */
  @Action(SwitchCurViewingService)
  switchCurViewingService(
    { getState, patchState }: StateContext<CreateBidStateModel>,
    { service: switchedToService }: SwitchCurViewingService
  ) {
    const state = getState();
    const updatedBids = this.updateBidsArrayWithNewServiceInfo(state, state.curViewingService);

    const currentBid = updatedBids.find(bid => bid.id === state.curViewingBid.id);
    const updatedSwitchedToService = currentBid.services.find(
      service => service.serviceData.serviceId === switchedToService.serviceData.serviceId
    );

    patchState({
      curViewingService: updatedSwitchedToService,
      allBids: updatedBids,
    });
  }

  @Action(AddCurViewingServiceAdditionalCharges)
  addCurViewingServiceAdditionalCharges(
    { patchState, getState }: StateContext<CreateBidStateModel>,
    { newAdditionalCharges }: AddCurViewingServiceAdditionalCharges
  ) {
    const { curViewingService, curViewingBid } = getState();
    const updatedService = {
      ...curViewingService,
      additionalCharges: [newAdditionalCharges, ...curViewingService.additionalCharges],
    };
    const updatedBids = this.updateBidsArrayWithNewServiceInfo(getState(), updatedService);

    const updatedCurViewingBid = {
      ...curViewingBid,
      total: curViewingBid.total + newAdditionalCharges.amount,
      services: curViewingBid.services.map(service =>
        service.serviceData.serviceId === updatedService.serviceData.serviceId ? updatedService : service
      ),
    };

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedCurViewingBid,
    });
  }

  @Action(RemoveCurViewingServiceAdditionalCharge)
  removeCurViewingServiceAdditionalCharge(
    { patchState, getState }: StateContext<CreateBidStateModel>,
    { index }: RemoveCurViewingServiceAdditionalCharge
  ) {
    const { curViewingService, curViewingBid } = getState();
    const updatedService = {
      ...curViewingService,
      additionalCharges: curViewingService.additionalCharges.filter((_, i) => i !== index),
    };

    const updatedBids = this.updateBidsArrayWithNewServiceInfo(getState(), updatedService);

    const updatedCurViewingBid = {
      ...curViewingBid,
      total: curViewingBid.total - curViewingService.additionalCharges[index].amount,
    };

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedCurViewingBid,
    });
  }

  @Action(UpdateCurViewingServiceAdditionalCharge)
  updateCurViewingServiceAdditionalCharge(
    { patchState, getState }: StateContext<CreateBidStateModel>,
    { index, newAdditionalCharge }: UpdateCurViewingServiceAdditionalCharge
  ) {
    const { curViewingService, curViewingBid } = getState();
    const updatedService = {
      ...curViewingService,
      additionalCharges: curViewingService.additionalCharges.map((charge, i) =>
        i === index ? { ...charge, ...newAdditionalCharge } : charge
      ),
    };

    const updatedCurViewingBid = {
      ...curViewingBid,
      total: curViewingBid.total - curViewingService.additionalCharges[index].amount + newAdditionalCharge.amount,
    };

    const updatedBids = this.updateBidsArrayWithNewServiceInfo(getState(), updatedService);

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedCurViewingBid,
    });
  }

  @Action(UpdateSubTotal)
  updateSubTotal({ patchState, getState }: StateContext<CreateBidStateModel>, { subTotal }: UpdateSubTotal) {
    const state = getState();
    const { curViewingService, curViewingBid } = state;

    const updatedService = { ...curViewingService, subTotal };
    const updatedBid = {
      ...curViewingBid,
      total: curViewingBid.total + subTotal - curViewingService.subTotal,
      services: curViewingBid.services.map(service =>
        service.serviceData.serviceId === updatedService.serviceData.serviceId ? updatedService : service
      ),
    };

    const updatedBids = this.updateBidsArrayWithNewServiceInfo(state, updatedService);

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(UpdateCurrentViewingServiceFCLRates)
  updateCurrentViewingServiceFCLRates(
    { patchState, getState }: StateContext<CreateBidStateModel>,
    { fclRates }: UpdateCurrentViewingServiceFCLRates
  ) {
    const { curViewingService } = getState();

    const currViewServiceSubTotal = fclRates.reduce((acc, rate) => acc + rate.unitPrice * rate.quantity, 0);
    const currViewBidTotal = getState().curViewingBid.total + currViewServiceSubTotal - curViewingService.subTotal;

    const updatedService = { ...curViewingService, fclRates, subTotal: currViewServiceSubTotal };
    const updatedBid = { ...getState().curViewingBid, total: currViewBidTotal };

    const updatedBids = this.updateBidsArrayWithNewServiceInfo(getState(), updatedService);

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(UpdateCurrentViewingServiceLCLRates)
  updateCurrentViewingServiceLCLRates(
    { patchState, getState }: StateContext<CreateBidStateModel>,
    { lclRates, subTotal }: UpdateCurrentViewingServiceLCLRates
  ) {
    const { curViewingService, curViewingBid } = getState();

    const currViewBidTotal = curViewingBid.total + subTotal - curViewingService.subTotal;
    const updatedService = { ...curViewingService, lclRates, subTotal };
    const updatedBid = { ...curViewingBid, total: currViewBidTotal };

    const updatedBids = this.updateBidsArrayWithNewServiceInfo(getState(), updatedService);

    patchState({
      curViewingService: updatedService,
      allBids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(ResetBidState)
  resetBidState({ patchState }: StateContext<CreateBidStateModel>) {
    patchState({
      allBids: [],
      currencies: [],
      selectedCurrency: null,
      curViewingBid: null,
      curViewingService: null,
      config: null,
    });
  }

  @Action(FetchMyBids)
  fetchMyBids({ patchState }: StateContext<CreateBidStateModel>, { jobRefId }: FetchMyBids) {
    if (!jobRefId) {
      return of(null);
    }
    return this.createBidService.getMyBids(jobRefId).pipe(
      take(1),
      tap(bids => {
        patchState({
          myBidCards: bids,
        });
      })
    );
  }

  @Action(SetAllBids)
  setAllBids({ patchState }: StateContext<CreateBidStateModel>, { bids }: SetAllBids) {
    patchState({
      allBids: bids,
      curViewingBid: bids[0],
      curViewingService: bids[0].services[0],
      selectedCurrency: bids[0].currency,
      currencies: [bids[0].currency],
    });
  }

  @Action(SetBidConfig)
  setBidConfig({ patchState }: StateContext<CreateBidStateModel>, { config }: SetBidConfig) {
    patchState({
      config,
    });
  }

  @Action(WithdrawBid)
  withdrawBid({ patchState, getState }: StateContext<CreateBidStateModel>, { bidId }: WithdrawBid) {
    if (!bidId) {
      return of(null);
    }
    return this.createBidService.withdrawBid(bidId).pipe(
      take(1),
      tap((result: boolean) => {
        if (!result) {
          this.notificationService.showError('Failed to withdraw bid');
        } else {
          this.notificationService.showSuccess('Bid withdrawn successfully');
        }
      })
    );
  }

  @Action(SetFormIsValid)
  setFormIsValid({ patchState }: StateContext<CreateBidStateModel>, { isValid }: SetFormIsValid) {
    patchState({
      formIsValid: isValid,
    });
  }

  /* IMPORTANT NOTE: Every time the currently viewing service is updated, this method is called to update the service in
  the bids array because when submitting we are using the bids array to send the data. Don't forget to update the
  bids array when the service is updated for future use. */
  private updateBidsArrayWithNewServiceInfo(state: CreateBidStateModel, updatedService: BidService): BidServiceData[] {
    return state.allBids.map(bid => {
      if (bid.id === state.curViewingBid.id) {
        const updatedServices = bid.services.map(service => {
          return service.serviceData.serviceId === updatedService.serviceData.serviceId
            ? { ...service, ...updatedService }
            : service;
        });
        return { ...bid, services: updatedServices };
      }
      return bid;
    });
  }

  private isBidValid(bid: BidServiceData): boolean {
    return bid.services.every(service => service.fclRates.every(rate => rate.unitPrice > 0));
  }
}
